body {
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
  display: none;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #4488f7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #275db3;
}

.nav-link.active {
  background-color: transparent;
  border-bottom: 2px solid #F69A00;
  color: #f0aa31;
}

.btn {
  border: none;
  display: block;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
}

.btn span {
  position: relative;
  z-index: 1;
}

.btn:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 600%;
  width: 150%;
  background: #3680f7;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  -webkit-transform: translateX(-110%) translateY(-25%) rotate(45deg);
  transform: translateX(-110%) translateY(-25%) rotate(45deg);
}

.btn:hover:after {
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

.scroll123 {
  offset-distance: 50%;
}

.bg-color {
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.23), #0f8cff);
}

.bg-shadow {
  box-shadow: 0px 0px 106px -3px #0633ad;
}

.hero-mobile-shadow {
  box-shadow: 1px 0px 108px 42px #0633ad;
  border-radius: 11%;
  background-color: #062C8E;
  min-width: 30%;
}

.comingSoonHead .comingSoonText {
  color: #fff;
  text-shadow: 2px 4px 4px rgba(46, 91, 173, 0.6);
  font-size: 5vw;
  -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, .6) 30%, #000 50%, rgba(0, 0, 0, .6) 70%);
  -webkit-mask-size: 200%;
  animation: comingSoon 2s linear infinite;
}

input:disabled {
  background-color: #3d3d3d;
  color: #b3b3b3;
}

@keyframes comingSoon {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}

@media (max-width: 1023px) {
  .hero-mobile-shadow {
    box-shadow: 1px 0px 108px 42px #0633ad;
  }

  .nav-link.active {
    background-color: transparent;
    border-bottom: 0px;
    color: #f0aa31;
  }
}

@media (max-width: 768px) {
  .comingSoonHead .comingSoonText {
    font-size: 8vw;
  }
}

@media (max-width: 400px) {
  .hero-mobile-shadow {
    min-width: 10%;
  }

  .comingSoonHead .comingSoonText {
    font-size: 10vw;
  }
}

.content ul {
  list-style-type: disc;
  margin-left: 40px;
  line-height: 30px;
}

.content ol {
  list-style-type: decimal;
  margin-left: 40px;
  line-height: 30px;
}

.content p {
  font-size: 16px;
  line-height: 28px;
}

.content h1 {
  font-size: 32px;
  line-height: 42px;
}

.content h2 {
  font-size: 24px;
  line-height: 48px;
  text-align: start;
}

.content h3 {
  font-size: 20px;
  line-height: 30px;
}

.content h4 {
  font-size: 16px;
  line-height: 24px;
}

.content h5 {
  font-size: 13px;
  line-height: 21px
}

.content h6 {
  font-size: 11px;
  line-height: 18px
}